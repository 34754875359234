<template>
  <div class="cpt-command-airway-list">
    <div class="hd" v-interact>
      <div class="left ml8">
        <img src="~@/assets/images/mount/mount_head.png" />
        <div class="title">航空航线</div>
      </div>
      <div @click="close" class="close">关闭</div>
    </div>

    <div class="list-box">
      <div class="tb-box">
        <div class="tb-hd-box">
          <div class="tb-hd">航线ID</div>
          <div class="tb-hd">航线名称</div>
          <div class="tb-hd">所属单位</div>
          <div class="tb-hd">空域状态</div>
          <div class="tb-hd">安全状态</div>
          <div class="tb-hd">航线标签</div>
          <div class="tb-hd last-tb-hd">操作</div>
        </div>
        <div class="tb-bd-box">
          <div class="tb-tr" v-for="item in list" :key="item.id">
            <div class="td">{{ item.id || "暂无" }}
            </div>
            <div class="td">
              <div>
                {{ item.title || "暂无" }}
              </div>
            </div>
            <div class="td">
              {{ item.organizationName || "暂无" }}
            </div>
            <!-- 空域状态 -->
            <div class="td">
              <div v-if="item.status == 1" class="status">可用</div>
              <div
                v-else-if="item.status == 2"
                class="status"
                style="color: #2ca1e2"
              >
                待申请
              </div>
              <div
                v-else-if="item.status == 3"
                class="status"
                style="color: #ffbd36"
              >
                待审批
              </div>
              <div
                v-else-if="item.status == 4"
                class="status"
                style="color: #2bfdf1"
              >
                通过
              </div>
              <div
                v-else-if="item.status == 5"
                class="status"
                style="color: #fb4a2d"
              >
                驳回
              </div>
              <div v-else>暂无</div>
            </div>
            <!-- 模式 -->
            <!-- <div class="td">{{ item.distance || "暂无" }}</div> -->
            <!-- 安全状态 -->
            <div
              class="td"
              :style="{ color: item.issafe == 1 ? '#19D864' : '' }"
            >
              {{ item.issafe == 1 ? "安全" : "待确定" }}
            </div>
            <!-- 航线标签 -->
            <div class="td">
              <!-- <span v-for="item2 in item.labelList"
                    :key="item2.labelId">{{ item2.labelName }}</span> -->
              {{ labelName ||"暂无" }}
            </div>
            <div class="td last-td" style="width: 15%">
              <div @click="changeLine(item)">选择航线</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import API from "@/api";

let point_index = null;
let isEditting = false;
let airline_entitys = [];

export default {
  inject: ["g_cesium_layer"],
  data () {
    return {
      keyword: null,
      pagination: {
        flightName: "",
        pageNo: 1,
        pageSize: 10,
        totalCount: 0,
    },
    list: [],
    };
  },
  mounted(){
    this.init()
  },
  methods: {
    async init() {
        let FlightLineList = await API.AIRWAY.Drd({sourceType:1});
        this.list = FlightLineList
    },
    async changeLine(item){
      if(item.issafe != 1){
        await this.$confirm('此航线为非安全航线，开始任务前请确认航线安全！', '安全确认', { customClass: "uav_controlPane", showClose: false, });
      }
      
      this.$emit("changeLine",item.id, item)
      this.close()
    },
    close(){
      this.$emit("close")
    }
  },
};
</script>

<style lang="scss" scoped>
@import "~@/styles/var.scss";
.cpt-command-airway-list {
  width: 600px;
  @import "~@/styles/flightTask.scss";
  position: absolute;
  right: -213px;
  top: 300px;
  // width: 1132px;
  // height: 689px;
  box-sizing: border-box;

  // background: rgba(0, 23, 79, 0.7);
  // box-shadow: 0 2px 4px 0 rgba(1, 162, 255, 0.35),
  //   inset 0 0 40px 0 rgba(0, 184, 255, 0.5);
  // border-radius: 13px;
  background: rgba(0, 23, 79, 0.7);
  box-shadow: 0 2px 4px 0 rgba(1, 162, 255, 0.35),
    inset 0 0 40px 0 rgba(0, 184, 255, 0.5);
  border-radius: 10px;

  .hd {
    height:32px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: rgba(16, 65, 215, 0.2);
    box-shadow: inset 0 0 15px 0 rgba(0, 182, 255, 0.6);
    border-radius: 10px 10px 0 0;

    .left {
      display: flex;
      align-items: center;
      .title {
        font-size: 20px;
        font-family: YouSheBiaoTiHei;
        color: #14faff;
        line-height: 26px;
        text-shadow: 0px 1px 1px rgba(2, 32, 56, 0.2);
        background: linear-gradient(
          135deg,
          #e3aa77 0%,
          #f5cda9 38%,
          #f9ecd3 58%,
          #fcdbb1 79%,
          #edb07a 100%
        );
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }

    .close {
      font-size: 14px;
      font-family: MicrosoftYaHei;
      color: #d2dfff;
      margin-right: 8px;
      cursor: pointer;
    }
  }

  .list-box {
    // width: 1132px;
    height: calc(100% - 56px);
    box-sizing: border-box;
    padding: 0 16px 0 16px;
    .search-box {
      margin-left: 0 !important;
      // height: 80px;
      height: auto;
      margin: 24px 0 24px 0;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      .item-plan {
        width: 79px;
        height: 32px;
        background: rgba(28, 67, 191, 0.6) !important;
        box-shadow: 0px 2px 4px 0px rgba(23, 33, 60, 0.5),
          inset 0px 0px 16px 0px rgba(33, 137, 255, 0.4),
          inset 0px 0px 4px 0px #00a7ff;
        border: 1px solid;
        border-image: linear-gradient(
            180deg,
            rgba(138, 218, 255, 1),
            rgba(82, 179, 255, 0)
          )
          1 1;
        border-radius: 0px;
        font-size: 12px;
        font-family: MicrosoftYaHei;
        color: #43deff;
        line-height: 16px;
      }
      .item-plan:hover {
        opacity: 0.5;
      }
      .item-input {
        width: 168px;
        height: 40px;
        margin-left: 10px;
        // margin-right: 10px;
        color: #08c2d1;
        ::v-deep .el-input__inner {
          background: rgba(2, 31, 51, 0);
          border: 0px solid #06b6e0;
          border-radius: 4px;
          font-family: MicrosoftYaHeiUI;
          font-size: 16px;
          color: #08c2d1;
          font-weight: 400;
          padding-left: 0;
          &::placeholder {
            font-size: 14px;
            font-family: MicrosoftYaHei;
            color: #397c8b;
            line-height: 19px;
          }
        }
      }
      .andLinlineBtn {
        position: absolute;
        top: 65px;
        right: 160px;
        width: 130px;
        cursor: pointer;
        height: 32px;
        opacity: 0.8;
        font-family: PangMenZhengDao;
        font-size: 22px;
        color: #00ffff;
        text-align: center;
        font-weight: 400;
        line-height: 40px;
      }
      .routeLabelBtn {
        position: absolute;
        top: 65px;
        right: 20px;
        width: 130px;
        cursor: pointer;
        height: 40px;
        opacity: 0.8;
        font-family: PangMenZhengDao;
        font-size: 22px;
        color: #00ffff;
        text-align: center;
        font-weight: 400;
        line-height: 40px;
      }
      .routeLabelBtnDefault {
        background: rgba(0, 3, 36, 0.8);
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5),
          inset 0 0 15px 0 rgba(0, 182, 255, 0.9);
        border-radius: 6px;
      }
      .routeLabelBtnActive {
        background: rgba(0, 34, 140, 0.2);
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5), inset 0 0 10px 0 #00b6ff;
        border-radius: 6px;
      }
    }
    .tb-box {
      // overflow-x: scroll;
      // overflow-y: hidden;
      padding: 0 !important;
      margin: 0 0 27px 0;
      .tb-hd-box {
          background: #081A3A;
        // width: 1415.62px;
        border-top: 1px solid rgba(207, 234, 255, 0.33);
        border-bottom: 1px solid rgba(207, 234, 255, 0.33);
        height: 37.6px;

        .tb-hd {
          line-height: 37.6px;
          width: calc(100% / 8);
          white-space: nowrap;
        }
      }
      .tb-bd-box {
        width: 570px;
        max-height: 280px;
        margin-bottom: 24px;
        overflow: hidden;
        overflow-y: auto;
        .tb-tr {
          // border-top: 1px solid rgba(207, 234, 255, 0.33);
          // border-bottom: 1px solid rgba(207, 234, 255, 0.33);
          height: 37.6px;
          width: 560px;
          margin: 5px 0 0 0;
          // border: 1px solid;
          background: #081A3A;
          cursor: pointer;
          // background-image: url("~@/assets/newImage/tiaokaung.png") !important;
          background-size: 100% 100%;
          // &:hover {
          //   // background: rgba(2, 19, 96, 0.2);
          //   box-shadow: inset 0px 0px 10px 2px #3fcbff;
          //   // border: 1px solid #70f6f9;
          // }
          &:nth-of-type(2n - 1){
          background: rgba(73,135,210,0.5);
          }
          .td {
            width: calc(100% / 7);
            overflow: hidden;
            text-overflow: ellipsis;
          }
          .last-td {
            text-align: center;
            width: 170px;
            div{
              background: url("~@/assets/images/btn.png") no-repeat;
              background-size: 100% 100%;
              padding: 5px 12px;
            }
            .iconfont {
              margin-right: 7px;
            }
            &:hover{
              color: #43deff;
            }
          }
        }
      }
    }
  }
}
.toubu {
  display: flex;
  .tiao {
    width: 3px !important;
    height: 15px;
    background: #ffbd36;
    margin: 6px 5px 0 16px;
  }
  .hd-box {
    font-size: 18px;
    font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
    font-weight: bold;
    color: #ffffff;
    line-height: 24px;
    text-shadow: 0px 2px 4px #136791;
  }
}
.cpt-command-airway-list .tb-box {
  height: 100%;
}
.flex {
  display: flex;
}
.uavImg {
  width: 20px;
  height: 20px;
  margin: 0 6px 0 6px;
  img {
    width: 100%;
    height: 100%;
  }
}
.fangkuai {
  border: 1px solid #43deff;
  height: 32px;
  background: rgba(13, 50, 92, 0.7);
}
.duanxian {
  width: 1px;
  height: 22px;
  border-left: 1px solid;
  border-image: linear-gradient(
      180deg,
      rgba(67, 222, 255, 0),
      rgba(67, 222, 255, 1),
      rgba(67, 222, 255, 0)
    )
    1 1;
}
/deep/ .el-input__suffix-inner {
  i {
    color: #43deff;
  }
}
// 滚动动画
.animate {
  padding-left: 20px;
  // font-size: 12px;
  // color: #000;
  display: inline-block;
  white-space: nowrap;
  animation: 5s wordsLoop linear infinite normal;
}

@keyframes wordsLoop {
  0% {
    transform: translateX(100%);

    -webkit-transform: translateX(100%);
  }

  100% {
    transform: translateX(-100%);

    // -webkit-transform: translateX(-100%);
  }
}

// @-webkit-keyframes wordsLoop {
//   0% {
//     transform: translateX(100%);

//     -webkit-transform: translateX(100%);
//   }

//   100% {
//     transform: translateX(-100%);

//     -webkit-transform: translateX(-100%);
//   }
// }
/deep/ .tb-tr .td {
  padding: 18px 0 !important;
}

// 提示框样式
/deep/ .td .el-tooltip {
  background: rgba(2, 19, 96, 0);
  border: 0px solid rgba(207, 234, 255, 0.33);
  font-family: MicrosoftYaHei;
  color: #ffffff;
  line-height: 19px;
}
// 空域状态
.status {
  font-size: 14px;
  font-family: MicrosoftYaHei;
  color: #00d45c;
  line-height: 19px;
}
//操作状态
.el-tooltip {
  opacity: 0.7;
}
.el-tooltip:hover {
  opacity: 1;
}
// 页签样式
.zongji {
  font-size: 12px;
  font-family: MicrosoftYaHei;
  color: #b5e5ff;
  line-height: 16px;
  .tiaoshu {
    color: #43deff;
  }
}
.douhao {
  margin: 0 5px 0 5px;
}
.dangqianye {
  margin: 0 20px 0 0;
}
/deep/.el-pager .number:hover {
  background: #00b6ff !important;
  border-radius: 2px;
  color: #000 !important;
  width: 14px;
  height: 22px;
  line-height: 22px;
}
/deep/ .el-pagination--small .el-pager .number {
  font-size: 12px;
  font-family: MicrosoftYaHei;
  color: #889fb2;
}
/deep/.active {
  color: #000 !important;
}
.btnqueding {
  margin: 0 0 0 8px;
  width: 79px;
  height: 32px;
  background: rgba(28, 67, 191, 0.6) !important;
  box-shadow: 0px 2px 4px 0px rgba(23, 33, 60, 0.5),
    inset 0px 0px 16px 0px rgba(33, 137, 255, 0.4),
    inset 0px 0px 4px 0px #00a7ff;
  border-radius: 0px;
  border: 1px solid;
  border-image: linear-gradient(
      180deg,
      rgba(138, 218, 255, 1),
      rgba(82, 179, 255, 0)
    )
    1 1;
  line-height: 1px;
  color: #43deff;
}
.btnqueding:hover {
  opacity: 0.5 !important;
}
.tb-pagination {
  margin: 0 0 19px 0;
}
.zhuan {
  font-size: 12px;
  font-family: MicrosoftYaHei;
  color: #889fb2;
  line-height: 16px;
  input {
    width: 48px;
    min-width: 48px;
    max-width: auto;
    text-align: center;
    height: 28px;
    background: rgba(12, 13, 20, 0.5);
    border-radius: 2px;
    border: 1px solid rgba(36, 146, 252, 0.3);
    margin: 0 5px 0 5px;
    color: #fff;
    outline: 0px solid;
  }
}
// 说明
.shuo {
  margin: 0 0 29px 0;
}
.shuoming {
  font-size: 12px;
  font-family: MicrosoftYaHei;
  color: #43deff;
  line-height: 16px;
}
.maohao {
  font-size: 12px;
  font-family: MicrosoftYaHei;
  color: #43deff;
  line-height: 16px;
  margin: 0 14px 0 5px;
}
.icons {
  font-size: 12px;
  font-family: MicrosoftYaHei;
  color: #b3bbc5;
  line-height: 16px;
  margin: 0 12px 0 0;
}
.cpt-command-flight-task-explain {
  display: flex;
  align-items: center;
  margin-left: 4px;
  // margin-top: 8px;
  padding: 0;
  margin: 28px 0 29px 0;

  .explain_title {
    font-family: MicrosoftYaHei;
    font-size: 14px;
    color: #08c2d1;
  }

  .explain_box {
    display: flex;
  }

  .explain_box_detail {
    margin-right: 15px;
    font-size: 12px;
    font-family: MicrosoftYaHei;
    color: #b3bbc5;
  }
}
.waixian {
  // border-radius: 6px;
  // border: 1px solid #8adaff;
}
/deep/ .el-tooltip__popper .is-dark {
  z-index: -100;
}
</style>